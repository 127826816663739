.t2g-header {
  padding: 1rem 0 0.4rem;

  .sponsor {
    color: #898989;
    font-weight: 500;
    font-size: 0.85rem;
    margin-top: 0.6rem;

    .sponsor-label {
      padding-top: 0.25rem;
      padding-right: 0;
    }
  }
}

.media-room-bar {
  background-color: #419b65;
  color: $white;
  padding: 0.75rem 0;
  h4 {
    font-weight: 800;
    color: $white;
    display: inline-block;
  }
  .media-room-bar-text {
    padding-top: 0.5rem;
    display: inline-block;
    margin-top: 0.5rem;
    margin-left: 0.6rem;

  }
  .media-room-bar-icon {
    margin-top: -0.5rem;
  }
}

.content {
  h4 {
    font-weight: 800;
  }

  &.interior-content {
    h4 {
      color: $black;
      text-transform: uppercase;
    }
  }

  background-image: url('../img/background-pattern.png');

  .content-panel {
    padding: 1rem 0;
  }

  .left-panel {
    background-color: $white;
    -webkit-box-shadow: -8px 0px 5px 0px rgba(137, 137, 137, 0.15),
                        8px 0px 5px 0px rgba(137, 137, 137, 0.15);
    -moz-box-shadow:    -8px 0px 5px 0px rgba(137, 137, 137, 0.15),
                        8px 0px 5px 0px rgba(137, 137, 137, 0.15);
    box-shadow:         -8px 0px 5px 0px rgba(137, 137, 137, 0.15),
                        8px 0px 5px 0px rgba(137, 137, 137, 0.15);
    //padding-left: 3rem;
  }

  .right-panel {
    padding-left: 3rem;
    border-right: 1px solid #ccc;

    h4 {
      margin-bottom: 1.5rem;
    }
  }

  .content-button {
    color: $white;
    font-weight: bold;
    background-color: $t2g_blue;
    padding: 1rem 5rem;
    -webkit-border-radius: 40px 40px 40px 40px;
    -moz-border-radius: 40px 40px 40px 40px;
    border-radius: 40px 40px 40px 40px;
    -webkit-box-shadow: none;
  }

}

.t2g-search {
  padding-top: 0.40rem;

  ::-webkit-input-placeholder {
    color: $t2g_blue;
    font-weight: 800;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: $t2g_blue;
    font-weight: 800;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: $t2g_blue;
    font-weight: 800;
  }

  :-ms-input-placeholder {
    color: $t2g_blue;
    font-weight: 800;
  }

  input.input-group-field {
    background-color: #ebebeb;
    border-radius: 40px 0 0 40px;
    -webkit-border-radius: 40px 0 0 40px;
    -moz-border-radius: 40px 0 0 40px;
    -webkit-box-shadow: none;
    -moz-box-shadow:   none;
    box-shadow:        none;
    border: none;
    width: 100%;
  }

  .input-group {
    width: 90%;
    max-width: 360px;
  }

  .input-group-button {
    background-color: #ebebeb;
    -webkit-border-radius: 0 40px 40px 0;
    -moz-border-radius: 0 40px 40px 0;
    border-radius: 0 40px 40px 0;
    width: 12%;
  }

  button {
    padding-right: 1rem;
  }

  .small-search {
    width: 2.4rem;
    .input-group-button {
      border-radius: 40px;
      button {
        padding: .5rem 0;
        img {
          max-width: 90%;
        }
      }
    }
  }

  .flush-left {
    padding-left: 0;
    margin-left: 0;
  }
}

.small-search-input {
  display: none;
  .icon-cancel:before {
    color: $t2g_blue;
  }
  input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    box-shadow: none!important;
    border-left: 0!important;
    border-right: 0!important;
    border-top: 0!important;
    padding: 0!important;
    margin-bottom: .25rem;
    color: $t2g_blue;
    height: 2rem;
    width: 87%;
    display: inline;
    margin-left: 3%;
  }
}

#slider-wrapper {
  position: relative;

  &.no-slider #menu-wrapper {
    position: relative;
    top: 0;
    padding-top: 1rem;
  }

  .title-bar {
    position: absolute;
    top: 0;
    width: 100%;
  }

  #breadcrumb-wrapper {
    float: none;
  }

  .breadcrumbs {
    margin-top: 1rem;
    a {
      color: #8e8e8e;
      text-transform: uppercase;
      &:hover {
        color: $t2g_blue;
      }
    }
  }

  .breadcrumbs li {
    color: #8e8e8e;
  }

  .breadcrumbs li:not(:last-child)::after {
    content: ">";
    font-weight: bold;
    color: #8e8e8e;
    z-index: -1;
  }

  #menu-wrapper {
    position: absolute;
    top: 1rem;
    #main-menu {
      //width: 100%;
      //float: none;
      background-color: $t2g_blue_dark;
      opacity: 0.90;
      -webkit-border-radius: 40px 40px 40px 40px;
      -moz-border-radius: 40px 40px 40px 40px;
      border-radius: 40px 40px 40px 40px;
      padding: 0;
      .title-bar {
        background: none;
        position: relative;
        padding: .5rem 1rem;
        .title-bar-left {
          .title-bar-title {
            text-transform: uppercase;
            font-size: 1.25rem;
          }
        }
        .title-bar-right {
          .menu-icon {
            margin-top: -3px;
            width: 40px;
            height: 32px;
            &::after {
              height: 7px;
              background: #23bbed;
              box-shadow: 0 13px 0 #23bbed, 0 26px 0 #23bbed;
              border-radius: 10px;
            }
          }
        }
      }
      .dropdown.menu {
        background: none;
        .main-menu-item {
          /*width: 19.5%;*/
          text-align: center;
          display: inline-block;
          padding-top: 0.5rem;
          padding-bottom: 0.4rem;
          a {
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
            padding: 0.4rem 0;
          }
          &.is-active:hover {
            a {
              color: $black;
            }
            a:hover {
              color: $t2g_blue;
            }
            .submenu a {
              color: $black;
            }
            .submenu a:hover {
              color: $t2g_blue;
            }
            background-color: $white;
            -webkit-border-radius: 20px 20px 0 0;
            -moz-border-radius: 20px 20px 0 0;
            border-radius: 20px 20px 0 0;
            opacity: 0.9;
          }
          &.is-active {
            a {
              color: $black;
            }
            a:hover {
              color: $t2g_blue;
            }
            .submenu a {
              color: $black;
            }
            .submenu a:hover {
              color: $t2g_blue;
            }
            background-color: $white;
            -webkit-border-radius: 20px 20px 0 0;
            -moz-border-radius: 20px 20px 0 0;
            border-radius: 20px 20px 0 0;
            opacity: 0.9;
          }
          &:hover {
            a {
              color: $t2g_blue;
            }
            background-color: $white;
            -webkit-border-radius: 20px 20px 20px 20px;
            -moz-border-radius: 20px 20px 20px 20px;
            border-radius: 20px 20px 20px 20px;
            opacity: 0.9;
          }
          &.active-section {
            a {
              color: $t2g_blue;
            }
            background-color: $white;
            -webkit-border-radius: 20px 20px 20px 20px;
            -moz-border-radius: 20px 20px 20px 20px;
            border-radius: 20px 20px 20px 20px;
            opacity: 0.9;
          }
        }
        .submenu {
          -webkit-border-radius: 0 0 20px 20px;
          -moz-border-radius: 0 0 20px 20px;
          border-radius: 0 0 20px 20px;
          width: 100%;
        }
      }
    }
  }
}

.subpage {
  #slider-wrapper {
    #menu-wrapper {
      #main-menu {
        .dropdown.menu {
          .main-menu-item {
            &.is-active:hover {
              background-color: #EAF3F9;
              opacity: 1;
            }
            &.is-active {
              background-color: #EAF3F9;
              opacity: 1;
            }
            &:hover {
              background-color: #EAF3F9;
              opacity: 1;
            }
            &.active-section {
              background-color: #EAF3F9;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .content-panel {
    p {
      font-size: 1.25rem;
    }
  }
}

.page {
  .content-panel {
    p {
      font-size: 1.25rem;
    }
  }
}

.participating-groups-wrap.show-for-medium {

  min-height: 348px;
}


#offCanvasRight {
  background-color: $t2g_blue_dark;
  height: 100%;
  padding: 2rem 1.5rem;
  .close-menu {
    color: #23bbed;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.05rem;
  }
  ul {
    padding: 2rem 0;
    margin: 0;
    li {
      list-style-type: none;
      line-height: 1.25rem;
      margin-bottom: 1.75rem;
      //display: flex;
      //align-items: center;
      a {
        color: $white;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: bold;
        width: 100%;
        display: inline-block;
        &.active {
          background: $white;
          border-radius: 10px;
          color: #23bbed;
          padding: .75rem 1.25rem;
          max-width: 100%!important;
          width: 100%;
        }
      }
      span {
        max-width: 81%;
        display: block;
        float: left;
      }
      i {
        width: 19%;
        float: right;
      }
      .slidedown {
        display: none;
        padding: 0;
        margin-top: .25rem;
        li {
          display: block;
          margin-bottom: .5rem;
          line-height: 1rem;
          a {
            font-size: .9rem;
          }
        }
      }
    }
    .icon::before {
      color: #23bbed;
      font-size: 2rem;
      background: $white;
      border-radius: 50%;
    }
  }
}

#t2g-slider {
  .orbit-bullets {
    button {
      width: 25%;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0;
      &.is-active {
        background-color: $white !important;
      }
    }
    button:nth-child(3) {
      //background-color: #FFC444;
      background-color: #f65db0;
    }
    button:nth-child(1) {
      //background-color: #C04825;
      background-color: #f6a321;
    }
    button:nth-child(2) {
      //background-color: #50baea;
      background-color: #50baea;
    }
    button:nth-child(4) {
      //background-color: #265c3c;
      background-color: #3ab073;
    }
    margin: 0;
  }
}

#t2g-carousel.owl-theme {
  .owl-controls {
    margin-top: 0;
    .owl-pagination {
      line-height: 0;
      .owl-page {
        width: 25%;
        span {
          border-radius: 0px;
          margin: 0px;
          width: 100%;
          height: 10px;
        }
      }
      .owl-page:nth-of-type(3) {
        span {
          //background-color: #FFC444;
          background-color: #f65db0;
        }
      }
      .owl-page:nth-of-type(1) {
        span {
          //background-color: #C04825;
          background-color: #f6a321;
        }
      }
      .owl-page:nth-of-type(2) {
        span {
          background-color: #50baea;
        }
      }
      .owl-page:nth-of-type(4) {
        span {
          //background-color: #265c3c;
          background-color: #3ab073;
        }
      }
      .owl-page.active {
        span {
          background-color: $white!important;
        }
      }
    }
  }
  #bar{
    width: 0%;
    max-width: 100%;
    height: 4px;
    background: #7fc242;
    &.slide-3 {
      //background-color: #FFC444;
      background-color: #f65db0;
    }
    &.slide-1 {
      //background-color: #C04825;
      background-color: #f6a321;
    }
    &.slide-2 {
      //background-color: #50baea;
      background-color: #50baea;
    }
    &.slide-4 {
      //background-color: #265c3c;
      background-color: #3ab073;
    }
  }
  #progressBar{
    width: 100%;
    background: #EDEDED;
  }
  .carousel-item {
    position:relative;
  }
  .carousel-text {
    color: $white;
    position: absolute;
    top: 35%;
    padding: 0 1.5rem;
    h1 {
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
    h2 {
      color: $white;
      line-height: 2.3rem;
    }
  }
  .owl-item {
    img {
      width: 100% !important;
    }
  }
}


.interior-carousel.owl-theme #bar.slide-1 {
  background-color: #f6a321!important;
}

/*mobile only*/
@media only screen and (max-width: 40.063em) {

  .content {
    .left-panel {
      background: none;
      box-shadow: none;
      padding-left: 0;
    }
    h4 {
      font-size: 1.65rem;
    }
  }

  #slider-wrapper {
    #menu-wrapper {
      #main-menu {
        .title-bar {
          padding: .5rem 1rem;
          .title-bar-left {
            .title-bar-title {
              font-size: .8rem;
            }
          }
          .title-bar-right {
            .menu-icon {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }

  #t2g-carousel.owl-theme {
    .carousel-text {
      top: 25%;
      padding: 0 .8rem;
      h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      h2 {
        font-size: 1.25rem;
        line-height: 1.25rem;
      }
    }
  }

  .participating-groups-wrap {
    padding: 1.5rem !important;
  }

  .columns.media-room-bar-text {
    padding-left: 0;
    padding-top: .6rem;
    h4 {
      font-size: 1.5rem;
    }
  }

  footer {
    .footer-inner.row {
      margin: 0;
      padding: 0;
    }
    .social-media-link {
      margin-right: .2rem;
    }
    .social-media-icon {
      font-size: 1.3rem;
    }
    .footer-center {
      padding: 0;
      margin-top: 0;
    }
    h6 {
      font-size: .8rem;
      margin-top: .5rem;
      line-height: 1rem;
    }
    .small-copyright {
      font-size: .7rem;
      margin-top: 1rem;
      line-height: .75rem;
    }
    .privacy-text {
      padding-left: 0;
    }
  }

}

/*tablet only*/
@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  #t2g-carousel.owl-theme {
    .carousel-text {
      top: 30%;
      h1 {
        font-size: 2.25rem;
        line-height: 2.25rem;
      }
      h2 {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
}

/*mobile and tablet*/
@media only screen and (max-width: 64em) {
  .diagnostic-image {
    margin: 1.5rem 0;
    float: none!important;
  }
}

@media screen and (min-width: 640px) {
  .top-bar > ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
  }
  .top-bar > ul > li {
    float: none;
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
  }
  .top-bar > ul > li > a {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px) and (max-width: 740px) {
  .t2g-search .input-group-button {
    width: 25%;
  }
}

@media only screen and (min-width: 64em) and (max-width: 90em) {
  #slider-wrapper #menu-wrapper #main-menu .dropdown.menu .main-menu-item a {
    font-size: .8rem;
  }
}

@media only screen and (min-width: 980px) and (max-width: 90em) {
  .left-text {
    margin-left: 3rem;
  }
  #t2g-carousel.owl-theme {
    .carousel-text {
      padding-left: 4rem;
    }
  }
  .breadcrumbs {
    padding-left: 3rem;
  }

  .front-left-text p {
    padding-right: 4rem;
    padding-top: 1rem!important;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  .columns.left-text {
    width: 60.6667%;
  }
}

//xl screens and up
@media only screen and (min-width: 90em) {
  //.front-left-text {
  //  padding-left: 2rem!important;
  //}
  .left-text {
    margin-left: 13.8%;
  }
  .columns.sub-left-text {
    padding-left: 0;
  }
}

#slider-wrapper .breadcrumbs li:not(:last-child)::after {
  z-index: 1;
}

.front-left-text {
  p {
    font-size: 1.25rem;
    padding-top: 3rem;
  }
}

.at-share-btn {
  width: 2.5rem!important;
}

.at-icon {
  height: 1.5rem!important;
  width: 1.5rem!important;
}
