footer {
  background-color: #ccc;
  color: $white;
  padding: 1.5rem 0;

  h6 {
    font-weight: bold;
    color: $white;
  }

  .collaborator {
    font-size: 0.82rem;
    font-weight: bold;
    line-height: 100%;
    .collaborator-text {
      display: inline-block;
    }
  }

  .footer-center {
    font-weight: 500;
    font-size: 0.85rem;
    margin-top: 1rem;

    img {
      margin-right: 1rem;
      &:last-of-type {
        margin-right: 0;
      }
    }

  }
  a {
    color: $white;
  }

  a:hover {
    color: $t2g_blue;
  }

  .social-media-icon {
    color: #ccc;
    background-color: $white;
    font-size: 1.9rem;
    padding: 0.5rem 0.35rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      background-color: $t2g_blue;
      color: $white;
    }
  }

  .social-media-link {
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
  }

}